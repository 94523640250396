import React, { useEffect, useState } from "react"
import moment from "moment";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts"
import { exportCSVFile } from "../../../components/util";

import Layout from "../../../components/layout"
import Intro from "../../../components/intro"


const fetchShelters = async shelter => {
  const URL = `https://mathproblems-api.herokuapp.com/shelters/${shelter}`
  const response = await fetch(URL)

  if (response.status === 200) {
    return await response.json()

  } else {
    alert('Maximum API calls reached - wait a minute then try again.')
  }
}

const fetchShelterList = async text => {
  const URL = `https://mathproblems-api.herokuapp.com/shelter_list`
  const response = await fetch(URL);

  if (response.status === 200) {
  const data = response.json();
  return data;
  } else {
        alert('Maximum API calls reached - wait a minute then try again.')
  }

}

const HomelessShelterMathQuestions = () => {
  const [tableData, setTableData] = useState();
  const [shelterList, setShelterList] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState();
  const [csvData, setCsvData] = useState();
  const [headers] = useState({
    date: 'date',
    occupancy: 'occupancy',
    capacity: 'capacity',
  });
  const [shelterData, setShelterData] = useState();

  const formatXAxis = (tickItem) => {
    // If using moment.js
    return moment(tickItem).format('MMM DD')
  }

  const formatTooltipLabel = (tickItem) => {
    // If using moment.js
    return moment(tickItem).format('MMM DD, YYYY')
  }

  const formatDateForCsv = (tickItem) => {
    // If using moment.js
    return moment(tickItem).format('MM/DD/YYYY')
  }

  useEffect(() => {
    fetchShelterList()
    .then(res => setShelterList(res))
  }, [])

  useEffect(() => {
    fetchShelters(selectedDropdown).then(res => {
      if (res)
      setTableData(res);
      setCsvData(res.map(item => {
        return {
          date: formatDateForCsv(item.occupancy_date),
          shelter: item.shleter_name,
          occupancy: item.occupancy,
          capacity: item.capacity,
        }
      }))
      setShelterData(res.map(item => {
        return {
          programName: item.program_name,
          organizationName: item.organization_name,
          postalCode: item.shelter_postal_code,
          address: item.shelter_address,
          city: item.shelter_city,
          sector: item.sector
        }
      })[0])
    })
    .catch(e => e)
  }, [selectedDropdown]);



  const meta = {
    title: "Homeless Shelter Capacity - Toronto, ON",
    subject: "Math",
    description: `Toronto shelters update their occupancy and capacity daily.`,
    questions: [
      "Are any shelters always over capacity?",
      "Find three shelters that are above capacity over 50% of the time.",
      "Is Covid-19 affecting the capacity of any shelters?"
    ],
    source: "City of Toronto Open Data",
  }

  return (
    <Layout>
      <Intro data={meta} />

      <div className="select">

      <select
        name="shelter"
        id="shelter-select"
        onChange={e =>
          setSelectedDropdown(e.target.value)
        }
      >
        <option>Select A Shelter</option>
        {shelterList.map((item, i) => (
            <option key={i} value={item.facility_name}>{item.facility_name}</option>
          ))}
      </select>
      </div>
      {
        selectedDropdown &&
      <button style={{marginLeft: 10}} className="button" onClick={() => exportCSVFile(headers, csvData, `Shelter - ${selectedDropdown}`)}>Download CSV of {selectedDropdown}'s Data</button>

      }
      
        <div>
          <br />
          <br />
          <br />
          {
            shelterData &&
          <div>
          <h2>{shelterData.programName}</h2>
          <p>{shelterData.organizationName}</p>
          <p>{shelterData.address}, {shelterData.city}, {shelterData.postalCode} - <a target="_blank" href={`https://www.google.com/maps/place/${shelterData.address}, ${shelterData.city}, ON, ${shelterData.postalCode}`}>Map</a></p>
          </div>
          }
          <br />
          <br />
          <br />
          {
            tableData && tableData.length > 0 &&
            <ResponsiveContainer width='100%' height={300}>

          <LineChart

            data={tableData}
          >
            <XAxis dataKey="occupancy_date" tickFormatter={formatXAxis} />
            <YAxis />
            <Tooltip labelFormatter={(value) => formatTooltipLabel(value)}/>
            <Line type="monotone" dataKey="capacity" stroke="#8884d8" dot={false} />
            <Line type="monotone" dataKey="occupancy" stroke="#82ca9d" dot={false} />
          </LineChart>
          </ResponsiveContainer>
          }

        </div>
    </Layout>
  )
}

export default HomelessShelterMathQuestions
